// Import Swiper and modules
import "./../sass/theme.scss";

import {
    Swiper
} from 'swiper';

import {
    Navigation,
    Thumbs,
    Autoplay,
    Pagination,
    EffectFade,
    Scrollbar
} from 'swiper/modules'


// Install modules
Swiper.use([Navigation, Thumbs, Autoplay, Pagination, EffectFade, Scrollbar]);


;(function ($, Drupal, once) {
    //scripts here

    // Homepage slider
    const swiperMain = new Swiper('.hp-slider', {
        loop: true,
        autoplay: true,
        disableOnInteraction: true,

        // Pagination
        pagination: {
            el: '.hp-slider__pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.hp-slider__btn--next',
            prevEl: '.hp-slider__btn--prev',
        },
    });

    if ($('.hp-slider').length > 0) {
        var mySwiper = document.querySelector('.hp-slider').swiper

        $(".hp-slider").mouseenter(function () {
            mySwiper.autoplay.stop();
        });

        $(".hp-slider").mouseleave(function () {
            mySwiper.autoplay.start();
        });
    }

    // Agenda slider
    const swiperAgenda = new Swiper('.hp-agenda__slider.swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 15,

        // Navigation arrows
        navigation: {
            nextEl: '.hp-agenda__btn--next',
            prevEl: '.hp-agenda__btn--prev',
        },

        scrollbar: {
            el: '.hp-agenda__scrollbar',
            draggable: true,
        },
    });


    $('.profile__identity').on('click', function () {
        $(this).toggleClass('is-active');
    });

    /*// Get the width of the scrollbar in .site-wrapper to help to align exactly the search bar with the content layout
    function _calculateScrollbarWidth() {
        const box = document.querySelector('.site-wrapper');
        document.documentElement.style.setProperty('--scrollbar-width', (box.offsetWidth - box.clientWidth) + "px");
    }

    // recalculate on resize
    window.addEventListener('resize', _calculateScrollbarWidth, false);
    // recalculate on dom load
    document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false);
    // recalculate on load (assets loaded as well)
    window.addEventListener('load', _calculateScrollbarWidth);*/


    // Menu
    var bodyEl = document.body,
        content = document.querySelector('.global-wrapper'),
        menu = document.querySelector('.header .menu');

    // prevent to close the menu if the user click in the menu
    $('html').click(function () {
        //Hide the menus if visible
        $(menu).find('li').removeClass("is-open");
    });

    $(menu).click(function (e) {
        e.stopPropagation();
    });

    /* Scroll to */
    if ($(window).width() > 991) {
        $('.anchor-link').click(function (e) {
            e.preventDefault();
            var target = $($(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top;
                $('html, body').animate({scrollTop: scrollTo + 'px'}, 800);
            }
        });
    } else {
        $('.anchor-link').click(function (e) {
            e.preventDefault();
            var target = $($(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top;
                $('html, body').animate({scrollTop: scrollTo + 'px'}, 800);
            }
        });
    }


    /* Tabs */
    $('ul.tabs li').on('click', function () {
        var tabId = $(this).attr('data-tab');

        $('ul.tabs li').removeClass('is-active');
        $('.tab-content').removeClass('is-active');

        $(this).addClass('is-active');
        $("#" + tabId).addClass('is-active');
    });

    /* Login page */
    $('input[name=windows_aad]').val('Se connecter');

    // When there are two timeframe with events following each other, add a class
    $(".event--events").each(function () {
        $(this).find(".timeframe--with-event").each(function () {
            if ($(this).next().is('a')) {
                $(this).addClass('larger');
            }
        });
    });

    // Scroll on annual calendar
    $('#right-button').click(function () {
        event.preventDefault();
        $('.annual__content').animate({
            scrollLeft: "+=150px"
        }, 400);
    });

    $('#left-button').click(function () {
        event.preventDefault();
        $('.annual__content').animate({
            scrollLeft: "-=150px"
        }, 400);
    });

    Drupal.behaviors.init_sliders = {
        attach: function (context, settings) {
            // Multiple Instances of block slider component on same page
            if ($('.block-slider__container').length > 0) { //block-slider-wrap-in
                $(once('init_sliders', '.block-slider__container', context)).each(function (index, element) {
                    // $(context).find(".block-slider__container").once("init_sliders").each(function (index, element) {
                    const $this = $(this);
                    $this.addClass("instance-" + index); //instance need to be unique (ex: some-slider)
                    $this.parent().find(".block-slider__nav-btn--prev").addClass("prev-" + index); //prev must be unique (ex: some-slider-prev)
                    $this.parent().find(".block-slider__nav-btn--next").addClass("next-" + index); //next must be unique (ex: some-slider-next)
                    new Swiper(".instance-" + index, {
                        loop: true,
                        effect: 'fade',
                        observer: true,
                        observeParents: true,
                        fadeEffect: {
                            crossFade: true
                        },
                        navigation: {
                            prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                            nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
                        },
                    });
                });
            }
        }
    }

    Drupal.behaviors.menu_civb = {
        attach: function (context, settings) {
            const menu_elements = $(once('menu_civb_open', '.menu li.has-children > .item__content > .item__arrow', context));
            menu_elements.each(menuHandler);

            const menu_parent_title = $(once('menu_civb_close', '.parent-title', context));
            menu_parent_title.each(menuCloseWithTitleHandler);

            $(".mainmenu li").each(function (index) {
                $(this).children('ul.is-child').find('> li').wrapAll('<div class="list-wrapper" />');
            });
        }
    };

    function menuHandler(index, elem) {
        elem.addEventListener('click', e => {
            $(elem).closest("li").toggleClass('is-open');
            $('.menu li.has-children.is-open').not($(elem).parents("li")).removeClass("is-open");
        });
    }

    function menuCloseWithTitleHandler(index, elem) {
        elem.addEventListener('click', e => {
            $(elem).closest('.is-open').removeClass('is-open');
        });
    }

    // Toggle menu
    $('.header__burger').on('click', function () {
        $('body').toggleClass('menu-is-visible');
    });

    // Disabled filters
    // TODO : UTILE ? À TESTER.
    setTimeout(function () {
        $('.select2-container--disabled').parent().addClass('disabled');
    }, 800);


    // Show list of documents on documents listing cards
    // if (('.card__show-list').length > 0) {
    //     $('.card__docs-quantity').on('click', function () {
    //         console.log('list');
    //         $(this).closest('.card__show-list').toggleClass('is-open');
    //     });
    // }
    Drupal.behaviors.listing_document = {
        attach: function (context, settings) {
            const listing_document = $(once('listing_document', '.card__docs-quantity', context));
            listing_document.each(showDocuments);
        }
    }

    // Show list of documents on documents listing cards
    function showDocuments(index, elem) {
        elem.addEventListener('click', e => {
            $(elem).closest('.card__show-list').toggleClass('is-open');
        });
    }


    Drupal.behaviors.keydown_search = {
        attach: function (context, settings) {
            $('#views-exposed-form-search-results-page .ui-autocomplete-input').on('keydown', function (e) {
                if (event.which == 13 || event.keyCode == 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    let query = document.getElementsByName('query')[0].value;
                    window.location = '/recherche?query=' + query;
                }
            });

        }
    };

    $('#civb-search-search #edit-submit').on('click', function (e) {
        e.preventDefault();
        let query = document.getElementsByName('query')[0].value;

        let countryQuery = '';
        let themeQuery = '';
        let dateQuery = '';
        let sortQuery = '&sort_bef_combine=search_api_relevance_DESC';

        let theme = document.getElementsByName('theme_facet')[0].selectedOptions[0].text;
        if (theme !== 'Thématique') {
            themeQuery = '&f[0]=theme%3A' + theme;
        }

        let country = document.getElementsByName('country_facet')[0].selectedOptions[0].text;
        if (country !== 'Pays') {
            countryQuery = '&f[1]=country%3A' + country;
        }


        let date = document.getElementsByName('date_facet')[0].selectedOptions[0].text;
        if (date !== 'Année') {
            dateQuery = '&f[2]=date_facet%3A' + date;
        }

        // Dirty but working
        let sort = document.querySelector("[id^='select2-edit-sort-bef-combine']").title;
        const sortOptions = {
            'Trier par': 'search_api_relevance_DESC',
            'Nom - De A à Z': 'title_ASC',
            'Nom - De Z à A': 'title_DESC',
            'Thématique - De A à Z': 'taxo_theme_facet_ASC',
            'Thématique - De Z à A': 'taxo_theme_facet_DESC',
            'Pays - De A à Z': 'taxo_country_facet_ASC',
            'Pays - De Z à A': 'taxo_country_facet_DESC',
            'Date - Du plus récent au plus ancien': 'created_facet_DESC',
            'Date - Du plus ancien au plus récent': 'created_facet_ASC',
        };

        sortQuery = '&sort_bef_combine=' + sortOptions[sort];

        window.location = '/recherche?query=' + query + themeQuery + countryQuery + dateQuery + sortQuery;
    });

    // User informations form.
    $('.account-informations').each(function () {
        const $container = $(this);
        let news = 0;
        $(this).on('click', '#new-form-btn', function (e) {
            const new_form = $container.find('.account-informations__new-employee')[0];
            const form = '<div class="account-informations__employee js-form-wrapper form-wrapper">' + new_form.innerHTML + '</div>';
            e.preventDefault();
            e.stopPropagation();

            $(this).prop('disabled', true);

            $(this).parent().fadeOut(function () {
                $(this).before(form);
                const $form = $(this).prev();
                news++;

                $form.find('input').each(function (i, e) {
                    if (e.id) e.id += `-${news}`;
                });
                $form.find('label').each(function (i, e) {
                    if ($(e).attr('for')) e.setAttribute('for', `${$(e).attr('for')}-${news}`);
                });
                setTimeout(function () {
                    $form.addClass('active');
                }, 10);
            });
        });

        $(this).on('click', '.account-informations__employee-title', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $(this).parent().toggleClass('active');
        });

        $(this).on('click', '.account-informations__tool', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).toggleClass('active');

            const name = $(this).find('input').attr('name');

            if ($(this).hasClass('active')) {
                $(`input[name="${name}"]`).prop('checked', true);
                $(`input[name="${name}"]`).val(1);
            } else {
                $(`input[name="${name}"]`).prop('checked', false);
                $(`input[name="${name}"]`).val(0);
            }
        });
    });

    $('body').on('click', '.profile-switcher', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('#switch-profile').addClass('active');
    });

    $('#switch-profile, #switch-profile .btn-close').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        $('#switch-profile').removeClass('active');
    });

    $('.switch-profile--modal').on('click', function (e) {
        e.stopPropagation();
    });

    $('.js-form-type-file input').on('change', function (e) {
        $(this).prev('label').text($(this)[0].files[0].name);
    });

    // Agenda ajax
    Drupal.behaviors.listing_agenda = {
        attach: function (context, settings) {
            const listing_agenda = $(once('listing_agenda', '.grid-listing--agenda', context));
            listing_agenda.each(initAgenda);
            listing_agenda.each(showAgenda);
        }
    }

    // Init list of agenda
    function initAgenda(index, elem) {
        $(elem).find(".views-row:hidden").slice(0, 8).show();
        if ($(elem).find(".views-row:hidden").length == 0) {
            $(".pager-show-more").css('visibility', 'hidden');
        }
    }

    // Show list of agenda
    function showAgenda(index, elem) {
        $("body").on('click touchstart', '.pager-show-more .pager__item a', function (e) {
            e.preventDefault();
            $(elem).find(".views-row:hidden").slice(0, 8).show();
            if ($(elem).find(".views-row:hidden").length == 0) {
                $(".pager-show-more").css('visibility', 'hidden');
            }
        });
    }

    $('#admin-users-link-apps').each(function () {
        const link = this;

        $('#edit-accounttype').each(function () {
            const match = /^([A-Z])\s*([0-9]+)/.exec($(this).val());

            if (match) {
                link.href = link.href.replace('__profile__', match[1] + match[2]);
            } else {
                $(link).parent().remove();
            }
        });
    });

    $('.lien-compte-client').on('click', function () {
        loading(`Interrogation de la base de données utilisateurs...`);
    });


    const search_profils = document.getElementById("search-profils");
    if (search_profils != null) {
        search_profils.addEventListener("keyup", searchProfils);
    }

    function searchProfils() {
        var input, filter, ul, li, a, i, txtValue, label;
        input = document.getElementById("search-profils");
        filter = input.value.toUpperCase();
        ul = document.getElementById("profils-list");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            label = li[i].getElementsByTagName("label")[0];
            txtValue = label.textContent || label.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }


    //
    // Tracking
    //

    // Connexion button.
    Drupal.behaviors.test = {
        attach: function (context, settings) {
            if (typeof dataLayer == "object") {

                // Au clic sur le CTA "Se connecter"
                $(once('click-openid', '.openid-connect-login-form .form-submit', context)).each(function () {
                    $(this).on('click', function () {
                        dataLayer.push({
                            'event': 'connexionCompte'
                        });
                    });
                });

                // Au clic sur le CTA "Créer un compte"
                $(once('click-create', '.connexion-page--container--create a', context)).each(function () {
                    $(this).on('click', function () {
                        dataLayer.push({
                            'event': 'creationCompte'
                        });
                    });
                });

                // Au clic sur le CTA "Récupérer mes accès"
                $(once('click-retrieve', '.connexion-page--container--retrieve a', context)).each(function () {
                    $(this).on('click', function () {
                        dataLayer.push({
                            'event': 'recupererCompte'
                        });
                    });
                });

                // Au clic sur le lien de l'outil
                $(once('click-toolsservices', '.toolsservices .hp-tools__item a', context)).each(function () {
                    $(this).on('click', function (e) {
                        const text = $(this).find('.hp-tools__title').text();
                        if (text !== '') {
                            dataLayer.push({
                                'toolName': text,
                                'event': 'outilExterne'
                            });
                        }
                    });
                });

                // Au clic sur le CTA "Télécharger"
                $(once('click-telechargement', '.block-download .block-download__item', context)).each(function () {
                    $(this).on('click', function (e) {
                        const text = $(this).find('.block-download__item-title').text().trim();
                        const title = $('body').find('.top-content h1').text().trim();
                        if (text !== '' && title !== '') {
                            dataLayer.push({
                                'docName': text,
                                'docCategory': title,
                                'event': 'telechargement'
                            });
                        }
                    });
                });

                // Au clic sur le CTA "Télécharger"
                $(once('click-listing-documents', '.listing-documents .document--table', context)).each(function () {
                    $(this).on('click', function (e) {
                        const title = $(this).find('.document__title').text().trim();
                        const cat = $(this).find('.document__theme-tag').text().trim();
                        if (cat !== '' && title !== '') {
                            dataLayer.push({
                                'docName': title,
                                'docCategory': cat,
                                'event': 'telechargement'
                            });
                        }
                    });
                });
            }
        }
    };

    // Popup cookie.
    $('.js-site-alert').each(function (i, e) {
        let id = $(this).attr('data-site-alert-id');
        if (getCookie('site_alert_' + id) != null) {
            $(this).remove();
        }
    });

    // Click on alert.
    $('.js-site-alert').on('click', function () {
        let id = $(this).attr('data-site-alert-id');
        let name = 'site_alert_' + id;
        setCookie(name, id, 30);
    });

    // Create cookie.
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // Read cookie
    function getCookie(name) {
        let end;
        let dc = document.cookie;
        let prefix = name + "=";
        let begin = dc.indexOf("; " + prefix);
        if (begin === -1) {
            begin = dc.indexOf(prefix);
            if (begin !== 0) return null;
        } else {
            begin += 2;
            end = document.cookie.indexOf(";", begin);
            if (end === -1) {
                end = dc.length;
            }
        }

        return decodeURI(dc.substring(begin + prefix.length, end));
    }

}(jQuery, Drupal, once));

window.loading = function (msg) {
    console.log('loading', msg);
    if (msg) {
        jQuery('#loader .loader--message').text(msg);
    }
    jQuery('#loader').fadeIn(300);
}

window.unloading = function () {
    jQuery('#loader').fadeOut(300);
}
